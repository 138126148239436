import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import axios from "axios";

const useStyles = makeStyles(() => ({
  root: {}
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const Fingerverify = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isOpen);
  const [fpvalues, setFpvalues] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  async function matchfp(userfp)
  {
    let or_fp = 'Rk1SACAyMAAAAADeAAABPAFiAMUAxQEAAAAoIIC0AOPDAEDLAOfFAECTAKyZAICcAQlNAEBeANqyAEDlAJ/gAIC8AS+gAEDVASWxAEBMARXBAIDtAHptAECAAVz2AECUANwNAICaAPwqAEDTAMPXAICyARiXAEBsAQjJAID1APXDAECnAHuIAECIAHiZAIB6ATv0AICpAUqMAEBvAFAWAICfALQCAIB9AMQgAECFAK8WAECYAJOXAEDuALjcAECRAH4IAED6ALhhAEEFAQvGAIDUAG/tAIA0APy2AAAA'
    var SuccessFlag=0;
            var primaryUrl = "http://127.0.0.1:";

						 

 
                let captureresponse = await axios({
                  method: 'POST', //you can set what request you want to be
                  url:  primaryUrl +"8004/mfs100/verify",
                  data: {
                    "ProbTemplate":or_fp,
                    "GalleryTemplate": userfp,
                    "BioType": "FMR", // you can paas here BioType as "ANSI" if you are using ANSI Template
                },
                  headers: {
                    'content-type':  "application/json; charset=utf-8"
                  }
                })

                alert(JSON.stringify(captureresponse.data))
                // setFpvalues({
                //   ...fpvalues,
                //   'finger': captureresponse.data.BitmapData
                // });
  
  }
  async function capture()
  {
      var SuccessFlag=0;
            var primaryUrl = "http://127.0.0.1:";

						 


         
             

               


                let captureresponse = await axios({
                  method: 'POST', //you can set what request you want to be
                  url:  primaryUrl +"8004/mfs100/capture",
                  data: {
                    "Quality": 60,
                    "TimeOut": 10
                },
                  headers: {
                    'content-type':  "application/json; charset=utf-8"
                  }
                })
                setFpvalues({
                  ...fpvalues,
                  'finger': captureresponse.data.BitmapData
                });
                matchfp(captureresponse.data.IsoTemplate)
   
  }
  
  return (
    <div>

    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
       Verify Finger Print
      </DialogTitle>
      <DialogContent dividers>
      <img style={{height: 200, width: 200}} src={`data:image/png;base64,${fpvalues.finger}`}/>

      </DialogContent>
      <DialogActions>
      
        <Button  onClick={capture} color="primary">
          Capture
        </Button>
        
      </DialogActions>
    </Dialog>
  </div>
  );
};

Fingerverify.propTypes = {
  className: PropTypes.string
};

export default Fingerverify;
