import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Hidden,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  },
  marginTop: {
    marginTop: 12
  },
  u: {  
    paddingBottom:"2px",  
    textDecorationStyle: "dotted",
    
},
  uo: {
    fontSize: 16,
    textDecoration: "underline",
  },
  middleDiv: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  templeName: {
    marginRight: 8,
    marginLeft: 8,
    marginBottom: 8,
    marginTop: 8,
    textAlign: "center",
  },
  titlediv:{
    textAlign: "center",
  },
  userphoto:{
    textAlign: "right",
  },
  dates: {
    padding: theme.spacing(1),
    backgroundColor: colors.grey[100]
  },
  Title: {
    padding: "8px 8px",
    border: "1px solid #0000A0",
    borderRadius: 4,
    marginRight:"64px",
    marginLeft:"64px",
    textAlign: "center"
  },
  signature: {
    height: 20,
  },
}));

const Proofview = props => {
  const { invoice, className, ...rest } = props;

  const classes = useStyles();
  const [community , setCommunity] = useState("")
  const [aadhaar , setAadhaar] = useState("")
  const [otherproof , setOtherproof] = useState("")
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  useEffect(() => {
  let memberinfo = props.invoice
  let community_obj = {}
  let aadhaaar_obj = {}
  let other_obj = {}
  if(memberinfo)
  {

   if(memberinfo.Member_certificates && memberinfo.Member_certificates.length > 1)
   {
      setCommunity(memberinfo.Member_certificates[0])

      memberinfo.Member_certificates.forEach(doc => {

          if(doc.proof_type == 1)
          {
            community_obj = doc
          }
          if(doc.proof_type == 2)
          {
            aadhaaar_obj = doc
         
          }
          if(doc.proof_type != 1 && doc.proof_type != 2)
          {
          other_obj = doc
         
          }
      })

      setCommunity(community_obj)
      setAadhaar(aadhaaar_obj)
      setOtherproof(other_obj)
    }
  }
 
    }, [props.invoice]);

      
   
  return (
    <Paper 
      {...rest}
      elevation={0}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Grid
          container
          justify="space-between"
         direction="column"
        >
          {community  && community.proof_url ?     <Grid item style={{marginTop:"100px",marginBottom:"100px", width:"100%",height:"880px"}}
>
            <img
             style={{width:"100%",height:"842px"}}

              alt={community.proof_url}
              src={community ? community.proof_url : ""}

            />
            </Grid> : null}
            {aadhaar && aadhaar.proof_url?     <Grid item  container
    spacing={0}
    align="center"
    justify="center"
    direction="column"
    style={{width:"100%",height:"880px",marginTop:"100px",marginBottom:"100px"}}>
            <img
            style={{width:"100%"}}
             
              alt="Brand"
              src={aadhaar ? aadhaar.proof_url : ""}

            />
        </Grid> : null}
          {otherproof && otherproof.proof_url ? <Grid item  style={{marginTop:"176px"}}>
            <img
              style={{width:"100%"}}
              alt="Brand"
              src={otherproof ? otherproof.proof_url : ""}

            /> 
          </Grid>: null}
       
      </Grid>
        
      
     
          
   </CardContent>

   
    </Paper>
  );
};

Proofview.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default Proofview;
