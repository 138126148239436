/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import BarChartIcon from '@material-ui/icons/BarChart';

import DashboardIcon from '@material-ui/icons/DashboardOutlined';

import HomeIcon from '@material-ui/icons/HomeOutlined';

import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import GroupIcon from '@material-ui/icons/Group';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import StoreIcon from '@material-ui/icons/Store';
import AppleIcon from '@material-ui/icons/Apple';

import ContactsIcon from '@material-ui/icons/Contacts';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import DraftsIcon from '@material-ui/icons/Drafts';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

export default [
  {
    title: 'User Mangement',
    pages: [
      {
        title: 'User ManageMent',
        href: '/dashboard',
        icon: BarChartIcon,
        children: [
          {
            title: 'Users',
            href: '/backofficeusers'
          },
          {
            title: 'Roles',
            href: '/masterroles'
          },
          {
            title: 'Locations',
            href: '/businesslocations'
          }
        ]
      }
    ]
  },
  {
    title: 'Contacts',
    pages: [
      {
        title: 'Customers',
        href: '/customer',
        icon: ContactsIcon
      },
      {
        title: 'Customer Groups',
        href: '/customergroups',
        icon: GroupIcon
      },
      {
        title: 'Suppliers',
        href: '/supplier',
        icon: AllInclusiveIcon
      }
    ]
  },
  {
    title: 'Product Management',
    pages: [
      {
        title: 'Products',
        href: '/products',
        icon: AppleIcon
      },
      {
        title: 'Settings',
        href: '/dashboard',
        icon: SettingsIcon,
        children: [
          {
            title: 'Categories',
            href: '/categories',
          },
          {
            title: 'Sub Categories',
            href: '/subcategories',
          },
          {
            title: 'Brands',
            href: '/brands',
          },
          {
            title: 'Units',
            href: '/units',
          },
          {
            title: 'Variations',
            href: '/variants',
          }
        ]
      },
      
    ]
  },
  {
    title: 'Purchase Order Management',
    pages: [{
      title: 'Purchase Orders',
      href: '/purchaseorder',
      icon: StoreIcon
    },
    {
      title: 'Return Purchase Orders',
      href: '/purchaseorder',
      icon: AssignmentReturnIcon
    }
    ]
  },
  {
    title: 'Sales Order Management',
    pages: [{
      title: 'Sales Orders',
      href: '/saleorder',
      icon: ShoppingBasketIcon
    },
    {
      title: 'Quotations',
      href: '/saleorder',
      icon: HomeIcon
    },
    {
      title: 'Drafts',
      href: '/saleorder',
      icon: DraftsIcon
    },
    {
      title: 'Return Sales Orders',
      href: '/saleorder',
      icon: AssignmentReturnIcon
    },
    {
      title: 'Invoices',
      href: '/invoices',
      icon: ReceiptIcon
    },
    {
      title: 'Shipments',
      href: '/shipment',
      icon: LocalShippingIcon

    },
    {
      title: 'Discount',
      href: '/discount',
      icon: LocalOfferIcon
    }
    ]
  },
  {
    title: 'Reports',
    pages: [
      {
        title: 'Reports',
        href: '/buyselldashboard',
        icon: DashboardIcon,
        children: [
          {
            title: 'Profit / Loss Report',
            href: '/buyselldashboard',
            icon: DashboardIcon
          },
          {
            title: 'Purchase & Sale Report',
            icon: DashboardIcon,
            href: '/buyselldashboard'
          },
          {
            title: 'Tax Report',
            icon: DashboardIcon,
            href: '/buyselldashboard'
          },
          {
            title: 'Stock Report',
            icon: DashboardIcon,
            href: '/buyselldashboard'
          },
          {
            title: 'Trending Products',
            icon: DashboardIcon,
            href: '/buyselldashboard'
          },
          {
            title: 'Product Purchase Report',
            icon: DashboardIcon,
            href: '/buyselldashboard'
          },
          {
            title: 'Product Sell Report',
            icon: DashboardIcon,
            href: '/buyselldashboard'
          },  
          {
            title: 'Sales Representative Report',
            icon: DashboardIcon,
            href: '/buyselldashboard'
          }, 
          
          
          
          
        ]
      }    
    ]
  },
 
  {
    title: 'Settings',
    pages: [
      {
        title: 'Profile',
        href: '/profile',
        icon: BarChartIcon
      },
      {
        title: 'Change Password',
        href: '/security',
        icon: BarChartIcon
      },
      {
        title: 'Preferences',
        href: '/preferences',
        icon: BarChartIcon
      },
      {
        title: '',
        href: '/events',
        icon: null
      }
    ]
  }
];
