import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4)
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  u: {  
    paddingBottom:"2px",  
    textDecorationStyle: "dotted",
    
},
  uo: {
    fontSize: 16,
    textDecoration: "underline",
  },
  middleDiv: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1%",
  },
  templeName: {
    marginRight: 8,
    marginLeft: 8,
    marginBottom: 8,
    marginTop: 8,
    textAlign: "center",
  },
  titlediv:{
    textAlign: "center",
  },
  userphoto:{
    textAlign: "right",
  },
  dates: {
    padding: theme.spacing(1),
    backgroundColor: colors.grey[100]
  },
  Title: {
    padding: "8px 8px",
    border: "1px solid #0000A0",
    borderRadius: 4,
    marginRight:"64px",
    marginLeft:"64px",
    textAlign: "center"
  },
  pledgeTitle: {
    padding: "4px 16px 4px 16px",
    border: "1px solid #0000A0",
    borderRadius: 4,
    marginRight:"64px",
    marginLeft:"64px",
    textAlign: "center"
  },
  
  signature: {
    height: 30,
  },
}));

const Details = props => {
  const { invoice, className, ...rest } = props;
  const [registerid, setRegisterid] = useState(0);

  const classes = useStyles();
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  function calculateage(dob)
  {
    if(dob)
    {
      var today = new Date();
    var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    console.log(age_now);
      return age_now;
    }else
    {
      return ""
    }
    
  }

  useEffect(() => {
    if(invoice)
    {
    if(invoice.Member_Registrations && invoice.Member_Registrations.length > 0)
    {
      let reg_id = invoice.Member_Registrations[0].register_id

      let register_year  = invoice.Member_Registrations[0].register_year
      let formatted_id = pad(reg_id,4)

      setRegisterid(formatted_id)
    }
  }
     
  },[invoice])
  return (
    <Paper 
      {...rest}
      elevation={0}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Grid
          container
          justify="space-between"
         
        >
          <Grid item xs={2}>
            <img
              height={120}
              width={120}
              alt="Brand"
              src="/images/logos/print_logo.png"
            />
          </Grid>
          <Grid item xs={8}
      >
            <Grid container justify="center">
              <Grid item >  
              <div className={classes.middleDiv}>
        <div  className={classes.uo}>
          <Typography  component="body2" style={{color: "#0000A0"}}>உ</Typography>
        </div>
        <Typography align="center" component="body2"
          variant="body2" style={{fontFamily: "latha",color: "#0000A0"}}>
          ஸ்ரீ காளிகாம்பாள் துணை </Typography>
          <br></br>
        <div className={classes.templeName}>
          <Typography color="#0000A0" align="center" component="h3"
          variant="h3" style={{lineHeight: 1.2,color:"#0000A0", fontFamily: "latha"}}>
         <b> சென்னை ஸ்ரீ காளிகாம்பாள் <br></br>கமடேஸ்வரர் தேவஸ்தானம்</b>
          </Typography>
        </div>
        <Typography align="center" component="body2"
          variant="body2" style={{lineHeight: 1.2,color: "#0000A0", fontFamily: "latha"}}>
            212, தம்புச் செட்டி தெரு, சென்னை - 600 001.<br></br>
          </Typography>
          <br></br>

        <div  className={classes.titlediv}>
        <Typography  style={{ fontFamily: "latha",color: "#0000A0"}} className={classes.Title}>
       <b>விஸ்வகர்ம சேவார்த்திகள் பதிவு  </b>
        </Typography>
        </div>
      
      </div>
      
              </Grid>
            </Grid>
          </Grid>
          <Grid item  xs={2} className={classes.userphoto}>
            <img
               height={120}
               width={120}
              alt="Brand"
              src={invoice.profile_pic ? invoice.profile_pic : "/images/avatar.jpg"}
            />
          </Grid>
        </Grid>
        <Grid
          alignItems="center"
          className={classes.marginTop}
          container
          justify="space-between"
        >
          <Grid item>
            <Typography style={{color: "#0000A0"}} variant="h6">எண்: &nbsp; {registerid}</Typography>
          </Grid>
          <Grid item>
            <Typography style={{color: "#0000A0"}} variant="h6" align="right">
              Date:&nbsp;   {invoice.Member_Registrations ?  moment(invoice.Member_Registrations[0].createdAt).format(
                            'DD MM YYYY (hh : mm a)' 
                          ) : ""}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.marginTop}>

        <Typography variant="body2" style={{ lineHeight: 2.0,color:"#0000A0", fontFamily: "latha"  }}>
        மதிப்பிற்குரிய அறங்காவலர்கள் அவர்களுக்கு,<br />
        &nbsp; &nbsp; &nbsp; &nbsp; மேற்படி தேவஸ்தானத்திற்காக ஏற்படுத்தப்பட்ட உயர்நீதிமன்ற சி.எஸ்.எண். 62/33,  நிர்வாகத் திட்டப்படி  {invoice.Member_Registrations ?  moment(invoice.Member_Registrations[0].createdAt).format(
                            'YYYY' 
                          ) : ""} வருடத்திய சேவார்த்திகள் பதிவேட்டில் ( Register of worshippers ) என் பெயரைப் பதிவு செய்யுமாறு தங்களைக் கேட்டுக் கொள்கிறேன். இத்துடன் ஒரு வருஷ சந்தாவாக 0.12 பைசா மட்டும் செலுத்தி இருக்கிறேன். <br></br>

          </Typography>
          </div>
        <Grid
          className={classes.marginTop}
          container
          justify="space-between"
        >
          <Grid item
          
          xs={6}
          >
        <Grid item
             container
             direction="row"
          xs={12}
          >

        <Grid item
                    container
                   xs={4}
                  >
              <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
           பெயர்:
            </Typography>
                  </Grid>

                  <Grid item
             
                  xs={8}
          >
               <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
              {invoice.Name}
            </Typography>

          </Grid>
          </Grid>
          <Grid item
             container
             direction="row"
          xs={12}
          >

        <Grid item
                    container
                   xs={4}
                  >
              <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
              தகப்பனார் பெயர்: 
            </Typography>
                  </Grid>

                  <Grid item
             
                  xs={8}
          >
               <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
              {invoice.fatherName} 
            </Typography>

          </Grid>
          </Grid>
          <Grid item
             container
             direction="row"
          xs={12}
          >

        <Grid item
                    container
                   xs={4}
                  >
              <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
              வயது : 
            </Typography>
                  </Grid>

                  <Grid item
             
                  xs={8}
          >
               <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
                  {calculateage(invoice.dob)}    
            </Typography>

          </Grid>
          </Grid>
       

          </Grid>

          <Grid item
          xs={6}
          >


<Grid item
             container
             direction="row"
          xs={12}
          >

        <Grid item
                    container
                   xs={4}
                  >
              <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
              அலைபேசி எண்:
            </Typography>
                  </Grid>

                  <Grid item
             
                  xs={8}
          >
               <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
              {invoice.mobile}  
            </Typography>

          </Grid>
          </Grid>
          <Grid item
             container
             direction="row"
          xs={12}
          >

        <Grid item
                    container
                   xs={4}
                  >
              <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
              ஈமெயில் :
            </Typography>
                  </Grid>

                  <Grid item
             
                  xs={8}
          >
               <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", }}>
               {invoice.email}
            </Typography>

          </Grid>
          </Grid>
          <Grid item
             container
             direction="row"
          xs={12}
          >

        <Grid item
                    container
                   xs={4}
                  >
              <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
              தொழில் : 
            </Typography>
                  </Grid>

                  <Grid item
             
                  xs={8}
          >
               <Typography variant="body2" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
               {invoice.occupation}  
            </Typography>

          </Grid>
          </Grid>
 
          </Grid>
          
          <Grid item
          xs={12}
          >
              <Typography variant="caption" style={{ lineHeight: 2.0, color : "#0000A0", fontFamily: "latha" }}>
                        <b>விலாசம் :</b> {invoice.address},{invoice.area},{invoice.city}- {invoice.pincode ? invoice.pincode : ""} <br />
                          </Typography>
                        </Grid>
                        <Grid  item
          xs={12}
          >
             <Typography
            align="right"
            component="h4"
            gutterBottom
            variant="overline"
            style={{color:  "#0000A0",marginRight: 16}}
          > 
         <b>   இப்படிக்கு </b>
          </Typography>
          </Grid>
        </Grid>

        
         
      
        <Grid container style={{marginTop : '8px'}}  justify="center">
            <Typography className={classes.pledgeTitle} style={{color:"#0000A0", fontFamily: "latha"}}>
            <b>   உறுதிமொழி</b>
        </Typography>
            </Grid>
            <div className={classes.marginTop}>

            <Typography variant="body2" align="center" style={{color:"#0000A0", lineHeight: 1.7, fontFamily: "latha" }}>
            <u>{invoice.Name} </u>ஆகிய நான் விஸ்வகர்ம சமூகத்தைச் சேர்ந்தவன் என்று உறுதி கூறுகிறேன்.

              </Typography>
              </div>

              <Grid  className={clsx(classes.marginTop)}
          container

          justify="space-between">
          <Grid item textAlign={"center"}>
            
          <Typography variant="body2"  align="center" style={{color:"#0000A0", lineHeight: 1.7,marginTop:"46px", fontFamily: "latha" }}>

          0.12 பைசா ரசீது பெற்றுக்கொண்டேன் 
          </Typography>
          </Grid>
          <Grid item>

         
          <div className={classes.signature}>
            </div>
            <div className={classes.marginTop}>
            <Typography
            align="right"
            component="h4"
            gutterBottom
            variant="overline"
            style={{color:  "#0000A0",marginRight: 16}}
          > 
         <b>   கையொப்பம்  </b>
          </Typography>
            <Typography

            marginTop={8}
            align="right"
            component="h4"
            gutterBottom
            
            variant="overline"
            style={{ fontFamily: "latha",color:"#0000A)",marginRight: 16  }}
          >
          <b>  {invoice.Name}   </b>
          </Typography>
          </div>
          </Grid>
        </Grid>
     
   </CardContent>
    </Paper>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default Details;
