import React, { useState, useRef, useEffect } from "react";
import uuid from "uuid/v1";
import moment from "moment";
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { Menu } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { NotificationsPopover } from "../../components";
import HomeIcon from '@material-ui/icons/Home';
import { useStyles } from "./styles";
import { withRouter } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import InputIcon from "@material-ui/icons/Input";
import SearchIcon from "@material-ui/icons/Search";
import LockIcon from "@material-ui/icons/LockOutlined";
import SideBar from "../SideBar";
import { GlobalContext } from "../../context";
import { CssBaseline } from "@material-ui/core";
import clsx from "clsx";
import "./style.css";
import { useHistory } from "react-router-dom";

function PAppBar(props) {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  let history = useHistory()

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { globalCtx, setGlobalCtx } = React.useContext(GlobalContext);
  const searchRef = useRef(null);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState([
    {
      id: uuid(),
      title: "New order has been received",
      type: "order",
      created_at: moment().subtract(2, "hours"),
    },
    {
      id: uuid(),
      title: "New customer is registered",
      type: "user",
      created_at: moment().subtract(1, "day"),
    },
    {
      id: uuid(),
      title: "Project has been approved",
      type: "project",
      created_at: moment().subtract(3, "days"),
    },
    {
      id: uuid(),
      title: "New feature has been added",
      type: "feature",
      created_at: moment().subtract(7, "days"),
    },
  ]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  const handleNotificationsOpen = () => {
   // setOpenNotifications(true);
   history.push('/userlists');
  };

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }
  const handleLogout = () => {

    localStorage.clear();

     history.push('/');
    // dispatch(logout());
  };

  const handleNavBarMobileOpen = () => {
    setGlobalCtx({ ...globalCtx, sideBarOpen: true });
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setGlobalCtx({ ...globalCtx, sideBarOpen: false });
    setOpenNavBarMobile(false);
  };
  function handleDrawer() {
    setGlobalCtx({ ...globalCtx, sideBarOpen: !globalCtx.sideBarOpen });
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };
  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };
  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const handlePricingOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <HomeIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  const popularSearches = [
    "Devias React Dashboard",
    "Devias",
    "Admin Pannel",
    "Project",
    "Pages",
  ];
  return (
    <>
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}
        color="primary"
      >
        <Toolbar>            <Typography
          style={{color: "white",fontFamily: "latha"}}
          variant="h3"
        >
         {`சென்னை ஸ்ரீ காளிகாம்பாள் கமடேஸ்வரர் தேவஸ்தானம்`}
        </Typography>
          <div className={classes.flexGrow} />
          <Hidden smDown>
            {/* <div
            className={classes.search}
            ref={searchRef}
          >
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search people &amp; places"
              value={searchValue}
            />
          </div> */}
            <Popper
              anchorEl={searchRef.current}
              className={classes.searchPopper}
              open={openSearchPopover}
              transition
            >
              <ClickAwayListener onClickAway={handleSearchPopverClose}>
                <Paper className={classes.searchPopperContent} elevation={3}>
                  <List>
                    {popularSearches.map((search) => (
                      <ListItem
                        button
                        key={search}
                        onClick={handleSearchPopverClose}
                      >
                        <ListItemIcon>
                          <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary={search} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
            {/* <Button
            className={classes.trialButton}
            onClick={handlePricingOpen}
            variant="contained"
          >
            <LockIcon className={classes.trialIcon} />
            Trial expired
          </Button> */}
          </Hidden>
          <Hidden mdDown>
            <IconButton
              className={classes.notificationsButton}
              color="inherit"
              onClick={handleNotificationsOpen}
              ref={notificationsRef}
            >
              
                <HomeIcon />
              
            </IconButton>

            <Button
              className={classes.logoutButton}
              color="inherit"
              onClick={handleLogout}
            >
              <InputIcon className={classes.logoutIcon} />
            </Button>
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={handleNavBarMobileOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
        {/* <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      />*/}
        <NotificationsPopover
          anchorEl={notificationsRef.current}
          notifications={notifications}
          onClose={handleNotificationsClose}
          open={openNotifications}
        />
      </AppBar>
    </>
  );
}

const PrimaryAppBar = withRouter(PAppBar);

const MainWrapper = (props) => {
  const classes = useStyles();
  let { component: Component, ...rest } = props;

  return (
    <main className={classes.container}>
      <Component {...rest} />
    </main>
  );
};

export const withAppBar = (component, props) => {
  function menuaction() {
    // setIsOpen(!isopen);
  }
  return (
    <>
      <CssBaseline />
      <PrimaryAppBar {...props} />
      {/* <SideBar {...props} /> */}
      <MainWrapper component={component} />
    </>
  );
};

export default PrimaryAppBar;
