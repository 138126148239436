const route = {
    login: '/login',
    profile: '/profile',
    security: '/security',
    preferences: '/preferences',
    adduser: '/adduser',
    departments: '/departments',
    specialities: '/specialities',
    events: '/events',
    participants: '/participants',
    orderdetails: '/orderdetails/:id',
    editproduct: '/editproduct/:id',
    addproduct: '/addproduct',
    editbroker: '/editbroker/:id',
    addbroker: '/addbroker',
    
    editemployee: '/editemployee/:id',
    viewuser: '/viewuser',

    customer : '/customer',
    editcustomer : '/editcustomer',

    
    supplier: '/supplier',
    editsupplier: '/editsupplier',
    viewsupplier: '/viewsupplier/:id',

    customergroups: '/customergroups',
    viewcustomergroups: '/viewcustomergroups/:id',
    editcustomergroups: '/editcustomergroups/:id',

    userlists: '/userlists',
    edituser: '/edituser',
    viewproduct: '/viewproducts/:id',

    categories: '/categories',
    editcategory: '/editcategories/:id',
    viewcategory: '/viewcategories/:id',
    
    subcategories: '/subcategories',
    editsubcategory: '/editsubcategories/:id',
    viewsubcategory: '/viewsubcategories/:id',

    brands: '/brands',
    editbrand: '/editbrands/:id',
    viewbrand: '/viewbrands/:id',

    units: '/units',

    variants: '/variants',
    viewsku: '/viewsku',
    purchaseorder: '/purchaseorder',
    editpurchaseorder: '/editpurchaseorder/:id',
    viewpurchaseorder: '/viewpurchaseorder/:id',

    businesslocations : '/businesslocations', 
    invoices : '/invoices',
    memberdetail : '/memberdetail',
    saleorder: '/saleorder',
    editsaleorder: '/editsaleorder/:id',
    viewsaleorder: '/viewsaleorder/:id',

    shipment: '/shipment',
    editshipment: '/editshipment/:id',
    viewshipment: '/viewshipment/:id',

    discount: '/discount',
    editdiscount: '/editdiscount/:id',
    viewdiscount: '/viewdiscount/:id',

    masterroles: '/masterroles',
    backofficeusers: '/backofficeusers',
    survey: '/survey',
    brokers: '/brokers',
    commodity: '/commodity',
    products: '/products',
    mastercommodity: '/mastercommodity',
    editcommodity: '/editcommodity/:id',
    addcommodity: '/addcommodity',
    news: '/news',
    addnews: '/addnews',
    editnews: '/editnews/:newsid',
    addstoreprice: '/editstoreprice/:rateid',
    buyselldashboard: '/buyselldashboard',
    editstoreprice: '/editstoreprice/:rateid/:viewtype',
    editmastercommodity: '/editmastercommodity/:id',
    addmastercommodity: '/addmastercommodity',
    editbuysell: '/editbuysell/:type/:id',
    addbuysell: '/addbuysell/:type',

    livesummary: '/livesummary/:productid',
    manageads: '/manageads',
    livelist: '/livelist',
    // userslist: '/userslist',
    managerates: '/managerates',
    managebuy: '/managebuy/:type',
    managesell: '/managesell',
    // edituser: '/edituser/:userid',
    dashboard: '/dashboard',
    profile: '/profile',
    eventlist: '/eventlist',
    productupload: '/createevent',
    configuration: '/Configuration',
    vendor: '/vendorlist',
    productlist: '/productlist',
    orderlist: '/orderlist',
    materiallist: '/material',
    producttypes: '/producttypes',
    voucherdiscount: '/voucherdiscount',
    priceupdate: '/priceupdate',
    productAttributes: '/product_attributes',
    diamondDetails: '/diamond_details',
    gemstoneDetails:'/gemstone-details',
    variants:'/variants',
    images:'/images',
    createVariant:'/create_variant',
    materiallistpage:'/newmaterial',
    editCategory:'/editcategory',
    dummyscreen:'/dummyscreen'


}

export default route;