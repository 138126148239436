import React from 'react';
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import  Page  from '../../components/Page';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import "./Configuration.css"

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    width: '100%',
   
    padding: theme.spacing(3)
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  filter: {
    marginTop: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(6),
    overflowY: 'auto'
  }
}));
export const Configuration = withRouter(props => {
  const master_options = ['Departments','Speciality', 'Events','Participants']
  const master_options_url = ['departments','specialities', 'events','participants']
  const classes = useStyles();

  const [raised, setRaised] = React.useState(false);
  const [cardindex, setCardindex] = React.useState(-1);

  const onMouseOver = () => 
  {
      setRaised(true);
  }
  const onMouseOut = () => 
  { 
      setRaised(false);
     // setCardindex(-1)

  }

   
  return (
    <Page
    className={classes.root}
    title="Projects List"
  >
    <Grid container  spacing={3}>  
    {/* <AddContact contactlist={[]}/> */}
    <Grid container item xs={12} sm={12} spacing={2}>
            <Typography component="h5" variant="h5">
            Configure
          </Typography>
    </Grid>
    
    {master_options.map((text, index) => (
    <Grid  item xs={6} sm={4} lg={4}  >
    <Link underline='none' component={RouterLink} to={master_options_url[index]}>
     <Card fullwidth
     className="card2">
        <CardContent >
          <Typography style={{textAlign: "center"}} component="h6" variant="h5">
            {text}
          </Typography>
          
          <Typography variant="body2" style={{textAlign: "center",marginTop:8}} color="textSecondary">
            Lorem Ipsum
          </Typography>
        </CardContent>
        
     
    </Card>
    </Link>
    </Grid>
    ))}

    
    </Grid>
    </Page>
  )
});

export default Configuration;