import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  Button,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  }
}));

const OrderItems = props => {
  const { order, customer, className, ...rest } = props;
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Registrations" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No</TableCell>
                  <TableCell>Month / Year</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer.Member_Registrations ? 
                  customer.Member_Registrations.map(item => (
                  <TableRow key={item.id}>
                    <TableCell>
                     {item.register_year +"-"+pad(item.register_id,4)}
                    </TableCell>
                    <TableCell>{moment(item.createdAt).format(
                            'MMM YYYY' 
                          )}</TableCell>
                    
                    <TableCell>
                      {"Rs "}
                      {"0.12"}
                    </TableCell>
                    <TableCell>{"paid"}</TableCell>
                    <TableCell>      <Button  component={RouterLink}
                                size="small"
                                to={{ 
                                  pathname: "/memberdetail", 
                                  state: {
                                    id : item.member_id,
								              	year : item.register_year
                                  }
                                 }}
                               variant="contained" color="primary" size="small">
          Print
        </Button></TableCell>
                  </TableRow>
                ))  : null}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

OrderItems.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderItems;
