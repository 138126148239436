import React from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import route from './route';
import { 

    ViewEmployee,
    
    Products, ProductEdit,
   
   InvoiceDetails,
    

    
    DashboardAnalytics,
    
    Login,  Configuration} from '../screens';


import PrivateRoute from './PrivateRoute';
import {  NetworkProvider } from '../context/NetworkContext';
import { GlobalContext } from '../context';
import { ApolloProvider } from 'react-apollo';
import { ProductContext,ProductProvider } from '../context/ProductuploadContext';

const MainApp = () => {

    const { globalCtx } = React.useContext(GlobalContext);
    const { productCtx } = React.useContext(ProductContext);
    const client = new ApolloClient({ uri: globalCtx.gqlClient, });

    return(
        <ApolloProvider client={client} >
            {/* <ProductProvider > */}
            <NetworkProvider>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route path={route.login} component={Login} />

                    <PrivateRoute path={route.viewuser} component={ViewEmployee} />

             
                    
                   
                  
                    <PrivateRoute  path={route.userlists} component={Products} />
                    <PrivateRoute  path={route.edituser} component={ProductEdit} />
               
                    <PrivateRoute  path={route.memberdetail} component={InvoiceDetails} />

                                 
                       <PrivateRoute  path={route.dashboard} component={DashboardAnalytics} />
                    {/* <PrivateRoute  path={route.edituser} component={EditUser} /> */}
                    
                  
                    
                   
                    
                    
                    <PrivateRoute  path={route.configuration} component={Configuration} />
                    

                    
          </Switch>
            </NetworkProvider>
            {/* </ProductProvider> */}
        </ApolloProvider>
    )
}


export default MainApp;