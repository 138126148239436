import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Search } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  search: {
    flexGrow: 1,

  },
  filterButton: {
    marginLeft: 'auto'
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  }
}));

const NewsFilter = props => {
  const { onFilter, onSearch,masters, className, ...rest } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleOptionchange = type => (e,option) => {
    
    props.onFilter(type, option);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item xs={6}>
        <Search
          className={classes.search}
          onSearch={onSearch}
        />
      </Grid>
      <Grid item xs={3}>
      <Autocomplete
                  multiple
                  id="combo-box-demo"
                  options={masters ? masters.commodity : []}
                  margin="dense"
                  fullWidth
                  onChange={handleOptionchange('commodity')}
                 // value={editcontent[columnname.defaultkey]}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label={"Commodity"} fullWidth margin="dense" variant="outlined" />}
                /> 
      </Grid>
      <Grid item xs={3}>
      <Autocomplete
                  
                  id="combo-box-demo"
                  options={[
                    {
                      label: "Active",
                      value: '1'
                    },
                    {
                      label: "In Active",
                      value: '0'
                    }
                  ]}
                  margin="dense"
                  fullWidth
                  onChange={handleOptionchange('status')}
                 // value={editcontent[columnname.defaultkey]}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label={"Status"} fullWidth margin="dense" variant="outlined" />}
                /> 
          </Grid>
    </Grid>
  );
};

NewsFilter.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func,
  masters: PropTypes.object
};

export default NewsFilter;
