import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {DialogTitle, TextField} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {}
}));
const titles = {
  "1" : "Add New User",
  "2" : "Edit New User",
  "3" : "Reset Password"
}
const AddVariant = props => {
  const { className, ...rest } = props;
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const [userobj, setUserobj] = React.useState(props.userobj ? props.userobj : {});

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const classes = useStyles();
  const handleSave = () => {
    props.onUpdate(userobj)
  };
  const handleinputchange = type => e => {
    setUserobj({...userobj,[type]:e.target.value})
  };
  const handleClose = () => {
    setOpen(false);
    props.onDismiss()
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{titles[props.viewtype]}</DialogTitle>
        <DialogContent>
          
        {props.viewtype == 1 || props.viewtype == 2 ? <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={userobj ? userobj.username : null}
                            autoComplete="off"
                            id="vendordeliverydays"
                            name="vendordeliverydays"
                            onChange={handleinputchange('username')}
                            label="Username"
                          /> : null}
                        {props.viewtype == 1 || props.viewtype == 3 ?   <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            type="password"
                            autoComplete="off"
                            id="vendordeliverydays"
                            name="vendordeliverydays"
                            onChange={handleinputchange('password')}
                           label="Password"
                          />  : null}
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddVariant.propTypes = {
  className: PropTypes.string
};

export default AddVariant;
