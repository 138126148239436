import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
  root: {},
  getAppIcon: {
    marginRight: theme.spacing(1)
  },
  actions: {
    marginTop: theme.spacing(3)
  }
}));

const Header = props => {
  const { invoice, className, ...rest } = props;

  const classes = useStyles();
  function printorder() {
    const printableElements = document.getElementById('printme').innerHTML;
    const orderHTML = '<html><head></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    window.print();
    document.body.innerHTML = oldPage
}
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
         <Grid item></Grid>
      <Grid item>
        <div className={classes.actions}>

        <Button
            color="primary"
            variant="contained"
            style={{marginRight: "16px"}}
            onClick={() => props.printdoc()}
          >
            <GetAppIcon className={classes.getAppIcon} />
            Print Doc
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => props.print()}
          >
            <GetAppIcon className={classes.getAppIcon} />
            Print
          </Button>
          </div>
          </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default Header;
