import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ModalImage from "react-modal-image";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import uuid from 'uuid/v1';
import moment from 'moment';
import  Page  from '../../components/Page';
import {  UserFilter } from '../../components';
import { NetworkContext } from '../../context/NetworkContext';

import { Header, CustomerInfo,MoreInfo,Proofview,OrderItems } from './components';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
  });
  function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));
export const ViewEmployee = withRouter(props => {
  const { className, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const [showsuccess, setShowsuccess] = React.useState({});
  const [memberid, setMemberid] = React.useState(0);
  const [regid, setRegid] = React.useState(0);

  const classes = useStyles();
  const [employeeobj , setEmployeeobj] = useState({
    

  });
  const [showpreview, setShowpreview] = useState(false);
  const [previewurl, setpreviewurl] = useState('');
  const [isrenew , setIsrenew] = useState(false)
  const [isuseredit , setIsuseredit] = useState(false)
 
  const [userid, setUserid] = useState('');
  const [viewtype, setViewtype] = useState(1);
  const [masters, setMasters] = useState({
    commodity :[]
  });

  const { sendNetworkRequest } = React.useContext(NetworkContext);

 
  async function fetchmasters()
  {
    let commodities = await sendNetworkRequest('/fetchcommodity', {}, {},false)
    
    setMasters({
      ...masters,
      commodity : commodities
    })
   
  }

  const handleRenew = async () => {
    setOpen(false);
	let prodinfo = await sendNetworkRequest('/addrenewal', {}, {member_id : memberid},false)
	if(prodinfo.statuscode == 200)
	{
		setShowsuccess({
			isshown : true,
			type: "success",
			message: prodinfo.message
		})
		
		checkrenewal(regid)

		updateproductinfo(regid)
	}else
	{
		setShowsuccess({
			isshown : true,
			type: "error",
			message: prodinfo.message
		})
	}
	
  };
  const handleClose = async () => {
    setOpen(false);
  };
  async function updateproductinfo(userid)
  {
    let prodinfo = await sendNetworkRequest('/getmemberinfo', {}, {reg_no : userid},false)
    setEmployeeobj(prodinfo.members);


  }
  async function renewmembership(member_id)
  {
    setOpen(true);
	setMemberid(member_id)
  }
 async function checkrenewal(userid)
  {
    let prodinfo = await sendNetworkRequest('/checkrenewed', {}, {member_id : userid},false)
   
    setIsrenew(prodinfo.isrenewed);
   
      
  }
  
  useEffect(() => {
    const { reg_id,isedit } = props.location.state
	setIsuseredit(isedit)
	setRegid(reg_id)
    checkrenewal(reg_id)

    updateproductinfo(reg_id)
  }, []);
  function previewimage(url)
  {
    setpreviewurl(url)
    setShowpreview(true)
  }
  return (
	  <>
	  {showpreview && (
          <Lightbox
            mainSrc={previewurl}
           // nextSrc={images[(photoIndex + 1) % images.length]}
           // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setShowpreview(false)}
            onMovePrevRequest={() =>
              alert('prev')
            }
            onMoveNextRequest={() =>
              alert('next')
            }
          />
        )}
    <Page
      className={classes.root}
      title="Commodity"
    >
      <Header  customer={employeeobj} isrenew={isrenew}  updaterenew={renewmembership} title={'User Information'}/>
      <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      direction="row"
      spacing={3}
    >
       <Grid
        item
        lg={4}
        md={6}
        xl={9}
        xs={12}
      >
     <CustomerInfo title="Basic Information" isedit={isuseredit} isrenew={isrenew} customer={employeeobj} masters={masters} viewtype={viewtype} onUpdate={updateproductinfo}/>  

      </Grid>
      <Grid
        item
        lg={8}
        container
        md={6}
        xl={9}
        xs={12}
      >
        <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >
     <OrderItems title="More Information" customer={employeeobj} masters={masters} viewtype={viewtype} onUpdate={updateproductinfo}/>  

      </Grid>
      
      <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >
     <Proofview  onPreview={previewimage} documents={employeeobj ? employeeobj : {}} title="More Information"/>  

      </Grid>
      </Grid>
     </Grid>
	 <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Have you verified all the details and want to renew for "+ moment(new Date(),"DD/MM/YYYY").year()+"?"}</DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
		  Have you verified all the details and want to renew for 2022?
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRenew} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
	  <Snackbar open={showsuccess.isshown} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={showsuccess.type} sx={{ width: '100%' }}>
          {showsuccess.message}
        </Alert>
      </Snackbar>
    </Page>

	</>
  );
});

export default ViewEmployee;
