import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const MoreInfo = props => {
  const { customer, className, ...rest } = props;

  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title={props.title} />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>
                {customer.gender}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Marital Status</TableCell>
              <TableCell>{customer.marital_status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Blood Group</TableCell>
              <TableCell>{customer.bgroup}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Alt Contact Number</TableCell>
              <TableCell>{customer.alt_contact_number}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>Emergency Contact Number</TableCell>
              <TableCell>{customer.emergency_contact_number}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Guardian Name</TableCell>
              <TableCell>{customer.gaurdian_name}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>Proof Type</TableCell>
              <TableCell>{customer.proof_type}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Proof Number</TableCell>
              <TableCell>{customer.proofnumber}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>Address 1</TableCell>
              <TableCell>{customer.current_address}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Address 2</TableCell>
              <TableCell>{customer.permanent_address}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      
     
    </Card>
  );
};

MoreInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default MoreInfo;
