import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Button, colors } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link as RouterLink } from 'react-router-dom';
import { NetworkContext } from '../../../../context/NetworkContext';

const useStyles = makeStyles(theme => ({
  root: {},
  toolbar: {
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  deleteIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = props => {
  const { order,customer, className, ...rest } = props;
  const [allowadd, setIsallowadd] = useState(true);
  const { sendNetworkRequest } = React.useContext(NetworkContext);

  const classes = useStyles();
  function renewupdate()
  {
	alert(customer.id)
  }

  async function checkallowadd()

  {
    let isallowed = await sendNetworkRequest('/checkmemberaddition', {}, {},true)
    setIsallowadd(isallowed.allow)

  }
  useEffect(() => {
    checkallowadd()
  }, []);
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            Reg No {pad(customer ? customer.id  : 0 ,4)}
          </Typography>
        </Grid>
        {allowadd && <Grid item>
       {props.isrenew ?  <Button
            className={classes.deleteButton}
            variant="contained"
            disabled
          > 
            {/* <DeleteIcon className={classes.deleteIcon} /> */}
            Renewed
          </Button> : <Button
            className={classes.deleteButton}
            variant="contained"
            to={{ 
              pathname: "/edituser", 
              state: {
                id : customer.id,
                isedit : true,
                isrenew: true
              }
             }}
             component={RouterLink}
			// onClick={() => props.updaterenew(customer.id)}
          > 
            {/* <DeleteIcon className={classes.deleteIcon} /> */}
            Renew
          </Button> }
        </Grid>}
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  updaterenew: PropTypes.func,
  order: PropTypes.object.isRequired
};

export default Header;
