import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const drawerWidth = 240;

export const useStyles =makeStyles(theme => ({
  root: {
    height: '100vh',
    overflowY: 'auto',
    marginTop : '64px',
    width: 256,
    minWidth: 256,
    flex: '0 0 auto',
    position: 'fixed',
    [theme.breakpoints.down('sm')]:{
      marginTop : '0px',
    }
  },
  content: {
    background : "#ffffff",
    padding: theme.spacing(2)
   
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor : colors.teal[900]
 
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  }
}));