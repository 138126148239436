import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import {

  Avatar,

  CardMedia,
  
  colors,
  Typography,
  Grid
} from '@material-ui/core';
import 'react-quill/dist/quill.snow.css'; // ES6

import {  registerPlugin } from "react-filepond"
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import { Card ,
  
  CardContent} from '@material-ui/core';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const useStyles = makeStyles(theme => ({
  root: {},
  cardroot: {
    maxWidth: 345,
    width: 345
  },
  content: {
    padding: theme.spacing(6)
  },
  marginTop: {
    marginTop: theme.spacing(4)
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100]
  },
  imagecontent: {
    padding: theme.spacing(2)
  },
  media: {
    height: 100,
    paddingTop: '56.25%', // 16:9
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  }
}));

const Review = props => {
  
  const { invoice,usercontent, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Grid
          container
          justify="space-between"
        >
        
          <Grid item xs={12}>
        
              <Typography color="#0000A0" align="center" component="h3"
          variant="h3" style={{lineHeight: 1.2,color:"#0000A0", fontFamily: "latha"}}>
         <b> சென்னை ஸ்ரீ காளிகாம்பாள் கமடேஸ்வரர் தேவஸ்தானம்</b>
          </Typography>
           
          </Grid>
        </Grid>
        <Grid
          alignItems="center"
          className={classes.marginTop}
          container
          justify="space-between"
        >

          {/* <Grid item>
            <Typography align="right" variant="h3">
              Registration #{"00084"}
            </Typography>
          </Grid> */}
        </Grid>
        <Grid
          className={classes.marginTop}
          container
          justify="space-between"
        >
                    <Grid item>
            <Avatar alt="Arun Jay" 
            src={usercontent.profile_pic ? usercontent.profile_pic : ""}
             variant="square" className={classes.large} />
             </Grid>
          
          <Grid item>
            <Typography variant="h5">{usercontent.Name}</Typography>
            <br />
            <Typography>
            
            {usercontent.fatherName}
           </Typography><br /> 
            <Typography>
              
            {moment(usercontent.dob).format(
                            'DD MM YYYY' 
                          )}
             
            </Typography>
           
          </Grid>
          <Grid item>
            <Typography>
              {
                usercontent.address
              }, <br />
              {
                usercontent.area
              },<br />
              {
              usercontent.city
              },<br />
              {
                 usercontent.pincode
              }
              
            </Typography>
          </Grid>
          
          <Grid item>
            <Typography align="left">
               {usercontent.fatherName} <br />
              {usercontent.occupation}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left">
               {usercontent.email} <br />
               {usercontent.mobile}
            </Typography>
          </Grid>
        </Grid>
        
        <Grid
          className={clsx(classes.marginTop, classes.dates)}
          container
          justify="space-around"
        >
          <Grid item>
            <Typography
              component="h4"
              gutterBottom
              variant="overline"
            >
              Community Certificate
            </Typography>
            <Typography>
              {/* {moment(invoice.due_date).format('DD MMM YYYY')} */}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="h4"
              gutterBottom
              variant="overline"
            >
              Aadhaar
            </Typography>
            <Typography>
              {/* {moment(invoice.issue_date).format('DD MMM YYYY')} */}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="h4"
              gutterBottom
              variant="overline"
            >
              Other
            </Typography>
            <Typography></Typography>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.marginTop, classes.imagecontent)}
          container
          justify="space-around"
        >
          <Grid item>
          <Card className={classes.cardroot}>

                <CardMedia
                  className={classes.media}
                  image={usercontent.community ? usercontent.community : ""}
                  title="Paella dish"
              />
              </Card>
            </Grid>
            <Grid item>
            <Card className={classes.cardroot}>

            <CardMedia
              className={classes.media}
              image={usercontent.aadhaar ? usercontent.aadhaar : ""}
              title="Paella dish"
            />
            </Card>
            </Grid>
            <Grid item>
            <Card className={classes.cardroot}>

              <CardMedia
                className={classes.media}
                image={usercontent.otherproof ? usercontent.otherproof : ""}
                title="Paella dish"
              />
              </Card>
            </Grid>
            </Grid>
         <div className={classes.marginTop}>
          <Typography
            component="h4"
            gutterBottom
            variant="overline"
          >
            Notes
          </Typography>
          <Typography>
            Please make sure you have to check details before submit.
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

Review.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool.isRequired,
  masters: PropTypes.object
};

export default Review;
