import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TextField} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [error, setError] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleadd = () => {
    if(name)
    {
      let isexist = false
      props.events.forEach(element => {
        if(element.name === name)
        {
          isexist = true
        }
      });
      if(!isexist)
      {
        setOpen(false);

        let obj = {
          name: name
        }
        props.onAdd(obj,-1)
        setName("")
        setError("")

      }else
      {
        setError("Component Already Exist")

      }
     
    }else{
      setError("Please enter name of the component")
    }
   

  };
  const handlechange = type => (event) => {
    setName(event.target.value)
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      
      <Dialog
        open={props.isshow}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={'sm'}
        fullWidth={true}

        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          value={name}
          id="discountvalue"
          name="discountvalue"
          onChange={handlechange('name')}
          error={error.length > 0}
          label={ 'Component Name'}
          helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onDismiss} color="primary">
          {props.negativebtn}

          </Button>
          <Button onClick={handleadd} color="primary">
            {props.positivebtn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}