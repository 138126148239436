import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { ReactTransliterate } from "react-transliterate";

import {
  Button,
 
  Drawer,

  Slider,
  TextField,
  Typography,
  Grid
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { NetworkContext } from '../../../../context/NetworkContext';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  fieldinput:{
    width: "100%", height: "36px", marginTop : "24px",
    border: "1px solid #cccccc",
    borderRadius: 4,
    
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
   
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const Filter = props => {
  const { open, onClose,masters, onFilter, className, ...rest } = props;
  const { sendNetworkRequest } = React.useContext(NetworkContext);

  const classes = useStyles();

  const initialValues = {
      
  };


  const [values, setValues] = useState({ ...initialValues });
  const [subcommodity, setSubcommodity] = React.useState([]);

  const handleClear = () => {
    setValues({ 
      });
  };

  
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function fetchsubcommodity(commodityID)
  {
    let bodycontent = {
      commodityID
    }

    let commodity_arr = await sendNetworkRequest('/fetchsubcommodity', {}, bodycontent,false)
    setSubcommodity(commodity_arr)
  }
  const handleinputchange = type => (e) => {
    if(type == 'pincode' && e.target.value.length == 7)
    {
      return
    }
    setValues({
      ...values,
      [type]: e.target.value
    })
  }

  const handleTagAdd = () => {
    setValues(values => {
      const newValues = { ...values };

      if (newValues.tag && !newValues.tags.includes(newValues.tag)) {
        newValues.tags = [...newValues.tags];
        newValues.tags.push(newValues.tag);
      }

      newValues.tag = '';

      return newValues;
    });
  };

  const handleTagDelete = tag => {
    setValues(values => {
      const newValues = { ...values };

      newValues.tags = newValues.tags.filter(t => t !== tag);

      return newValues;
    });
  };
  const handleDateChange = type => (date) => {
    setValues({
      ...values,
      [type]: date
    })
  };
  

  const handleSubmit = event => {
    event.preventDefault();
    onFilter && onFilter(values);
    onClose && onClose()
  };

  const paymentStatusOptions = ['Pending', 'Canceled', 'Completed', 'Rejected'];
  const customerAgeOption = ['18 - 30', '30 - 45', '50 - 60', '60+'];

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
                      {/* <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="vendordeliverydays"
                            name="vendordeliverydays"
                            value={values.area}
                            onChange={handleinputchange('area')}
                           label="Area"
                          /> */}

                                    <div className="container">

                                    <ReactTransliterate
                                      className={classes.fieldinput}
                                      value={values.area ? values.area : ""}
                                      onChange={handleinputchange('area')}
                                      lang="ta"
                                      autoComplete="off"
                                      placeholder="Area"
                                      containerStyles={{
                                        width: "100%",
                                        height: "80px"
                                      }}
                                    />
                                    </div>
            </Grid>
            {/* <Grid item xs={12}>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="vendordeliverydays"
                            name="vendordeliverydays"
                            value={values.name}
                            onChange={handleinputchange('name')}
                           label="State"
                          />
            </Grid> */}
            <Grid item xs={12}>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            type="number"
                            fullWidth
                            autoComplete="off"
                            id="vendordeliverydays"
                            name="vendordeliverydays"
                            value={values.pincode ? values.pincode : ""}
                            onChange={handleinputchange('pincode')}
                           label="Pincode"
                          />
            </Grid>
            {/* <Grid item xs={12}>
            <Typography id="range-slider" gutterBottom>
        Age
      </Typography>
            <Slider
              value={value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
             // getAriaValueText={valuetext}
            />
            </Grid>     */}
           
           </Grid>
        </div>
        <div className={classes.actions}>
          <Button
            fullWidth
            onClick={handleClear}
            variant="contained"
          >
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
          >
            Apply filters
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  masters : PropTypes.object,
  open: PropTypes.bool.isRequired
};

export default Filter;
