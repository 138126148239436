import React, { useState, useEffect } from 'react';
import uuid from 'uuid/v1';
import { colors } from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// import axios from 'utils/axios';
import { GenericMoreButton } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  avatar: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.white
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const TopReferrals = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchReferrals = () => {
      // axios.get('/api/dashboard/top-referrals').then(response => {
      //   if (mounted) {
      //     setReferrals(response.data.referrals);
      //   }
      // });
      setReferrals([
        {
          id: uuid(),
          color: colors.blueGrey['700'],
          name: 'GitHub',
          initials: 'GT',
          value: '53,032'
        },
        {
          id: uuid(),
          color: colors.cyan['500'],
          name: 'Twitter',
          initials: 'TW',
          value: '39,551'
        },
        {
          id: uuid(),
          color: colors.indigo[600],
          name: 'Hacker News',
          initials: 'HN',
          value: '23,150'
        },
        {
          id: uuid(),
          color: colors.red['500'],
          name: 'Stack Overflow',
          initials: 'SO',
          value: '14,093'
        },
        {
          id: uuid(),
          color: colors.orange['900'],
          name: 'Reddit.com',
          initials: 'RD',
          value: '7,251'
        },
        {
          id: uuid(),
          color: colors.blueGrey['900'],
          name: 'Dev.to',
          initials: 'DE',
          value: '5,694'
        },
        {
          id: uuid(),
          color: colors.blue['900'],
          name: 'Facebook',
          initials: 'FB',
          value: '3,643'
        },
        {
          id: uuid(),
          color: colors.blueGrey['900'],
          name: 'Medium',
          initials: 'MD',
          value: '1,654'
        }
      ])
    };

    fetchReferrals();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={<GenericMoreButton />}
        title="Top Referrals"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List disablePadding>
          {referrals.map((referral, i) => (
            <ListItem
              divider={i < referrals.length - 1}
              key={referral.id}
            >
              <ListItemAvatar>
                <Avatar
                  className={classes.avatar}
                  size="small"
                  style={{ backgroundColor: referral.color }}
                >
                  {referral.initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={referral.name} />
              <Typography variant="subtitle2">{referral.value}</Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="#"
          variant="text"
        >
          See all
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
};

TopReferrals.propTypes = {
  className: PropTypes.string
};

export default TopReferrals;
