import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import MainApp from './routes';
import MomentUtils from '@date-io/moment';
import { GlobalProvider } from './context';
import { CssBaseline } from '@material-ui/core';
import theme from './theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// const theme = createMuiTheme({
//   typography: {
//     fontFamily: [
   
//       'Roboto'
  
//     ].join(','),
//   },
// });
function App() {
  
  return (
    <GlobalProvider>
    <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>

        <div className="App">
          <CssBaseline />
            <Router>
              <MainApp />
            </Router>
        </div>
        </MuiPickersUtilsProvider>
        </MuiThemeProvider  >
    </GlobalProvider>

  );
}

export default App;
