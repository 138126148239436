import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import Color from 'color';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Image,
  Divider,
  Link,
  Avatar
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import  Page  from '../../components/Page';
import gradients from '../../utils/gradients';
import { LoginForm } from './components';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2)
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    color: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32
  },
  loginForm: {
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  person: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },

  titlebg: {
    
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));
export const Login = withRouter(props => {
  const [alertoption, setAlertoption] = React.useState({});

  const classes = useStyles();
  function showalert(message,isopen, alerttype)
  {
    setAlertoption({
      isopen,
      alerttype,
      message
    })
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      
      
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          {/* <Image className={classes.icon} /> */}
          <Avatar alt="Kaalikambaal" src="/images/logos/profile_logo.png" className={classes.icon} />

          <Typography
            gutterBottom
            variant="h3"
          >
            Sign In  
          </Typography>
          <Typography variant="subtitle2">
            SignIn on the Internal platform
          </Typography>
      {alertoption.isopen ?  <Alert  severity={alertoption.alerttype}>
         {alertoption.message}
        </Alert> : null }
          <LoginForm onShow={showalert} className={classes.loginForm} />
{/*           
          <Divider className={classes.divider} />
          <Link
            align="center"
            color="secondary"
            component={RouterLink}
            to="/auth/register"
            underline="always"
            variant="subtitle2"
          >
            Don't have an account?
          </Link> */}
        </CardContent>
        <CardMedia
          className={classes.media}
          image="/images/logos/profile_cover.png"
          title="Cover"
        >
        <div className={classes.titlebg}>

          <Typography
            color="inherit"
            component="h3"
            variant="h3"
            style={{fontFamily : "latha"}}
          >
            சென்னை ஸ்ரீ காளிகாம்பாள் கமடேஸ்வரர் தேவஸ்தானம்
            {/* விஸ்வகர்ம சேவார்த்திகள் பதிவு */}
          </Typography>
          </div>
          <div className={classes.person}>
            {/* <Avatar
              alt="Person"
              className={classes.avatar}
              src="/images/avatars/avatar_2.png"
            /> */}
            <div>
              {/* <Typography
                color="inherit"
                variant="body1"
              >
                Ekaterina Tankova
              </Typography>
              <Typography
                color="inherit"
                variant="body2"
              >
                Manager at inVision
              </Typography> */}
            </div>
          </div>
        </CardMedia>
      </Card>
    </Page>
  );
});

export default Login;
