import React, { Fragment, useEffect }  from 'react';
import clsx from 'clsx';
import {  useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
  import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Hidden } from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import { useStyles } from './styles';
import Collapse from '@material-ui/core/Collapse';
import StarBorder from '@material-ui/icons/StarBorder';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { GlobalContext } from '../../context';
import { NetworkContext } from '../../context/NetworkContext';

import {  Paper, Avatar, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import navigationConfig from './navigationConfig';
import  Navigation  from '../Navigation';

function SideBar(props) {
  const { openNavBarMobile, onMobileClose,sideMobileNavbarOpen, className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { globalCtx, setGlobalCtx } = React.useContext(GlobalContext);
  const { sendNetworkRequest } = React.useContext(NetworkContext);
  const [ userinfo, setUserinfo] = React.useState({});

  const handleDrawer = () => {
    setGlobalCtx({ ...globalCtx, sideBarOpen: !globalCtx.sideBarOpen })
  }
  const handleClick = type => e => {
      setGlobalCtx({ ...globalCtx, "optionname": type})
  }
  function handleListItemClick(event, index)
  {
    setGlobalCtx({ ...globalCtx, selectedIndex: index})

  }
 async function fetchuserinfo()
  {
    let userdata = localStorage.getItem('userdata')
    let content = JSON.parse(userdata)
    setUserinfo(content)
  }
  React.useEffect(()=> {
    fetchuserinfo()
  },[])
  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          src="/images/logos/profile_logo.png"
          alt="Prasanna"
        />
        <Typography
          className={classes.name}
          variant="h4"
        >
         {`Baker's Bazaar`}
        </Typography>
        <Typography variant="body2">{userinfo ? userinfo.role : ''}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </div>
  );
debugger
  return (
    <Fragment>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          onClose={()=>{setGlobalCtx({...globalCtx, sideBarOpen:false})}}
          open={globalCtx.sideBarOpen}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
}

export default withRouter(SideBar);