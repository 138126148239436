import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 160,
    height: 160,
  },
  panelHead: {
    display: "flex",
    justifyContent: "space-between",
  },
  uo: {
    fontSize: 16,
    textDecoration: "underline",
  },
  middleDiv: {
    marginTop: "1%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1%",
  },
  templeName: {
    margin: 28,
    textAlign: "left",
  },
  titlediv:{
    width: "100%",
  },
  Title: {
    padding: "14px 6px",
    border: "1px solid #000",
    width: 380,
    borderRadius: 4,
  },
}));

export function HeaderDiv(props) {
  const classes = useStyles();
  return (
    <div className={classes.panelHead}>
      <div>
        <img
          src={"https://picsum.photos/200"}
          alt={"image1"}
          className={classes.image}
        />
      </div>
      <div className={classes.middleDiv}>
        <div className={classes.uo}>
          <Typography>உ</Typography>
        </div>
        <div className={classes.templeName}>
          <Typography>
          சென்னை ஸ்ரீ காளிகாம்பாள் கடமேஸ்வரர் தேவஸ்தானம்,
          212, தம்புச் செட்டி தெரு, சென்னை - 600 001.
          </Typography>
        </div>
        <div className={classes.titlediv}>
        <Typography className={classes.Title}>
        விஸ்வகர்ம சேவார்த்திகள் பதிவு 
        </Typography>
      </div>
      
      </div>
      <div>
        <img
          src={"https://picsum.photos/200"}
          alt={"image2"}
          className={classes.image}
        />
      </div>
    </div>
  );
}
